<template>
  <div class="contents">
    <div class="title flexB">
      <h2>마이페이지</h2>
      <p>{{ name }} ({{ team }})</p>
    </div>
    <div class="box mypage">
      <section class="register">
        <h2>내 정보 관리</h2>
        <div class="registerBox">
          <div class="flexL">
            <p>이메일</p>
            <span>{{ email }}</span>
          </div>
          <div class="flexL">
            <p>이름</p>
            <div>
              <span>{{ name }}</span>
            </div>
          </div>
          <div class="flexL">
            <p>팀명</p>
            <select v-model="teamId">
              <option
                v-for="item in options"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </option>
            </select>
          </div>
          <div class="flexL">
            <p>휴대폰</p>
            <span>{{ getMask(mobileNo) }}</span>
          </div>
          <div class="flexL">
            <p>비밀번호</p>
            <input type="password" disabled v-model="imaginaryPassword" />
            <button @click="handleModal()">비밀번호 수정</button>
          </div>
        </div>
      </section>
      <div class="buttons">
        <button class="basic" @click="submit">수정</button>
      </div>

      <transition name="fade">
        <div class="dim" v-if="visible">
          <ChangePassword @close="close" @getProfile="getProfile" />
          <div class="dimbg" @click="close"></div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { fetchProfile, fetchTeamList, updateUser } from "@/api/index";
import { format } from "@/mixins/format";
import ChangePassword from "@/components/pop/ChangePassword";
import { mapState } from "vuex";
export default {
  mixins: [format],
  name: "myPage",
  components: { ChangePassword },
  data() {
    return {
      moment: moment,
      inputMode: "",
      searchWord: "",
      email: "",
      name: "",
      mobileNo: "",
      currentPage: 1,
      isNodata: false,
      team: "",
      teamName: "",
      teamId: "",
      visible: false,
      activeName: "myInfo",
      passwordLength: 0,
      imaginaryPassword: "",
      options: [],
    };
  },
  computed: {
    ...mapState(["_id"]),
  },
  activated() {
    this.$store.dispatch("SET_NAVBAR", "2");
    this.getProfile();
    this.getTeamList();
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },

    search() {
      if (this.inputMode == "") {
        return alert("검색 조건을 선택해 주세요.");
      }
      this.getUserList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUserList();
    },
    getProfile() {
      fetchProfile().then((res) => {
        if (res.data.status == 200) {
          this.mobileNo = res.data.data.mobileNo;
          this.email = res.data.data.email;
          this.name = res.data.data.name;
          this.team = res.data.data.team.teamName;
          this.teamId = res.data.data.team._id;
          this.passwordLength = res.data.data.passwordLength;
          this.imaginaryPassword = Math.random()
            .toString(36)
            .substring(2, this.passwordLength + 2);
        }
      });
    },
    getTeamList() {
      fetchTeamList().then((res) => {
        if (res.data.status == 200) {
          this.teamList = res.data.data;
          this.options = res.data.data.map((item) => {
            return { value: item._id, label: item.teamName };
          });
        }
      });
    },
    submit() {
      if (this.teamId == "") {
        return alert("팀을 선택해 주세요.");
      }
      let data = {
        team: this.teamId,
      };
      updateUser(data).then((res) => {
        if (res.data.status == 200) {
          alert("회원정보가 변경 되었습니다.");
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    handleModal() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
  },
};
</script>
