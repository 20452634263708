<template>
  <div class="dimbox">
    <div class="mHeader">
      <p>비밀번호 수정</p>
    </div>
    <div class="mBody">
      <div class="registerBox">
        <div class="flexL">
          <p>현재 비밀번호</p>
          <input
            type="password"
            placeholder="8~12자이내, 영문+숫자+특수문자"
            v-model="password"
          />
          <div class="errorMessage"><span v-if="error == 'password'" class="error flr"
            >현재 비밀번호를 입력해 주세요.</span
          ></div>
          
        </div>
        <div class="flexL">
          <p>새 비밀번호</p>
          <input
            type="password"
            placeholder="8~12자이내, 영문+숫자+특수문자"
            v-model="newPassword"
          />
          <div class="errorMessage">
            <span v-if="error == 'newPassword'" class="error flr">비밀번호를 입력해 주세요.</span>
          <span v-if="error == 'checkPassword'" class="error flr">8~12자이내, 영문+숫자+특수문자</span>
        </div>
          
        </div>
        <div class="flexL">
          <p>새 비밀번호 재확인</p>
          <input
            type="password"
            placeholder="8~12자이내, 영문+숫자+특수문자"
            v-model="passwordConfirm"
          />
          <div class="errorMessage">
            <span v-if="error == 'passwordConfirm'" class="error flr">비밀번호가 일치하지 않습니다.</span>
          </div>
          
        </div>
      </div>
      <div class="buttonWrap">
        <button @click="submit()" class="main">수정</button>
        <button @click="close" class="sub">취소</button>
      </div>
    </div>
  </div>
</template>
<script>
import { updatePassword } from "@/api/index";
import { format } from "@/mixins/format";
export default {
  mixins: [format],
  data() {
    return {
      password: "",
      newPassword: "",
      passwordConfirm: "",
      error: "",
      errorMsg: "",
    };
  },

  activated() {},
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      if (this.password == "") {
        this.error = "password";
        return;
      } else if (this.newPassword == "") {
        this.error = "newPassword";
        return;
      } else if (this.checkPassword(this.newPassword) == false) {
        this.error = "checkPassword";
        return;
      } else if (this.newPassword != this.passwordConfirm) {
        this.error = "passwordConfirm";
        return;
      }
      this.error = "";

      let data = {
        password: this.password,
        newPassword: this.newPassword,
      };
      updatePassword(data).then((res) => {
        if (res.data.status == 200) {
          this.$emit("getProfile");
          this.$emit("close");
          alert("비밀번호가 변경 되었습니다.");
        } 
        else {
          alert(res.data.message);
          this.error = "etc";
          this.errorMsg = res.data.message;
          // let message = res.data.message;
          // alert(message);
        }
      });
    },
  },
};
</script>
